import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import InputText from "../../../components/Common/InputText";
import * as url from "../../../helpers/url_helper";
import PaginationService from "../../../components/Common/PaginationService";

import Select2Async from "../../../components/Common/Select2Async";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import $filter from "../../../common/utils/$filter";
import BillingStatusBadge from "./BillingStatusBadge";
import QueryString from "qs";
import InputDate from "../../../components/Common/InputDate";
import BillingViewAdmin from "./BillingViewAdmin";
import BillingStatusIntegracaoBadge from "./BillingStatusIntegracaoBadge";
import moment from "moment-timezone";

function BillingV2(props) {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  /**
   * @Type {ColumnDescription[]}
   */
  const columns = [
    {
      dataField: "id",
      text: "Boleto Id",
    },
    {
      dataField: "statusIntegration",
      text: "Status Integração",
      formatter: (cell, row) => (
        <BillingStatusIntegracaoBadge key={row.id} status={cell} />
      ),
    },
    {
      dataField: "orderId",
      text: "Cobrança Id",
    },
    {
      dataField: "dueDate",
      text: "Data Vencimento",
      formatter: (cell, row) => <span id={row}>{$filter(cell, "DATE")}</span>,
    },
    {
      dataField: "amount",
      text: "Valor Boleto",
      formatter: (cell, row) => <span id={row}>{$filter(cell, "MONEY")}</span>,
    },
    {
      dataField: "sellerName",
      text: "Fornecedor",
      formatter: (cell, row) => (
        <span className="colunm-h-name-wrap" id={row} about={cell} title={cell}>
          {cell}
        </span>
      ),
    },
    {
      dataField: "billingNum",
      text: "Número Da Parcela",
    },
    {
      dataField: "sellerId",
      text: "CNPJ Fornecedor",
      formatter: (cell, row) => (
        <span id={row}>{$filter(cell, "CPF_CNPJ")}</span>
      ),
    },
    {
      dataField: "buyerName",
      text: "Cliente Final",
      formatter: (cell, row) => (
        <span className="colunm-h-name-wrap" id={row} about={cell} title={cell}>
          {cell}
        </span>
      ),
    },
    {
      dataField: "buyerId",
      text: "Cliente Final CNPJ",
      formatter: (cell, row) => (
        <span id={row}>{$filter(cell, "CPF_CNPJ")}</span>
      ),
    },
    {
      dataField: "statusBilling",
      text: "Status Boleto",
      formatter: (cell, row) => (
        <BillingStatusBadge key={row.id} status={cell} />
      ),
    },
  ];


  const [pager, setPager] = useState(PaginationService.defaultPager());
  const [filter, setFilter] = useState(PaginationService.defaultFilter(10, 1));
  const [loading, setLoading] = useState(false);
  const [statusSelects, setStatusSelects] = useState({});
  const [error, setError] = useState(null);
  const [totalOfItems, setTotalOfItems] = useState(0);
  const applySearch = async (
    currentPage,
    itemsPerPage,
    filterDefault = filter
  ) => {
    try {
      const { pagerResult, filterResult } =
        await PaginationService.applySearchV2(
          url.GET_BILLING_ADMIN,
          "data",
          filterDefault,
          currentPage,
          itemsPerPage,
          setLoading
        );
      setPager(pagerResult);
      setFilter(filterResult);
      updateQueryUrl(filterResult);

      setTotalOfItems(pagerResult?.pagination?.totalOfItems || 0);
    } catch (e) {
      if (e["status"] !== "200" && e["status"] !== "403") {
        setError(e);
      }
    }
  };

  const handleChangeFilterRangeCalendar = (event, field) => {
    if (!filter[field]) {
      filter[field] = {};
    }

    filter[field].init = event?.init?.value;
    filter[field].end = event?.end?.value;
    // setFilterField("filter", filter);
    setFilter(filter);
  };

  const handleChangeFilter = (event, field) => {
    filter[field] = event.target.value;

    // setFilterField("filter", filter);
    setFilter(filter);
  };

  const handlerSelect = (event, field) => {
    filter[field] = event?.value || undefined;
    setStatusSelects((v) => ({
      ...v,
      [field]: event,
    }));
    // setFilterField("filter", filter);
    setFilter((f) => ({ ...f, ...filter }));
    applySearch();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFilter(PaginationService.resetPagination2(filter));
    applySearch();
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    const filterInit = PaginationService.defaultFilter(20, 1);
    setStatusSelects((v) => ({
      ...v,
      status: null,
      buyerId: null,
      financialAgentId: null,
      sellerId: null,
      paymentMethod: null,
    }));
    document.getElementById("searchForm").reset({
      invoiceNumber: undefined,
      status: undefined,
      buyerId: undefined,
      sellerId: undefined,
      financialAgentId: undefined,
      paymentMethod: undefined,
    });

    await setFilter((_f) => ({
      ...filterInit,
    }));
    await applySearch(
      filterInit["pagination.currentPage"],
      filterInit["pagination.itemsPerPage"],
      filterInit
    );
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenId, setIsOpenId] = useState(null);
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setIsOpenId(row.id);
      setIsOpen(!isOpen);
    },
    onMouseEnter: (e, row, rowIndex) => {},
  };

  const updateQueryUrl = (filter) => {
    if (filter) {
      const formatquery = QueryString.stringify(filter);

      props.history.push({
        search: formatquery,
      });
    }
  };

  useEffect(() => {
    if (query.size) {
      async function fetchData() {
        const filterInit = QueryString.parse(query.toString());
        await setFilter((f) => ({
          ...f,
          ...filterInit,
        }));
        await applySearch(
          filterInit["pagination.currentPage"],
          filterInit["pagination.itemsPerPage"],
          filterInit
        );
        setStatusSelects((v) => ({
          ...v,
          status: {
            value: filterInit["status"],
          },
          buyerId: {
            value: filterInit["buyerId"],
            label: filterInit["buyerId"],
          },
          sellerId: {
            value: filterInit["sellerId"],
            label: filterInit["sellerId"],
          },
          financialAgentId: {
            value: filterInit["financialAgentId"],
            label: filterInit["financialAgentId"],
          },
          paymentMethod: {
            value: filterInit["paymentMethod"],
          },
        }));
      }
      fetchData();
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Boletos")}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Boleto")}
            breadcrumbItem={props.t("Gerenciamento de Boletos")}
          />
          <Form
            id="searchForm"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
            default={{
              invoiceNumber: undefined,
              status: undefined,
              buyerId: undefined,
              financialAgentId: undefined,
              sellerId: undefined,
              paymentMethod: undefined,
            }}
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p>{props.t("Filters parameters")}</p>
                    <Row>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <InputText
                          id="id"
                          onChange={(event) => handleChangeFilter(event, "id")}
                          placeholder={"Id Boleto"}
                          autoComplete="off"
                        />
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <InputText
                          id="orderId"
                          onChange={(event) =>
                            handleChangeFilter(event, "orderId")
                          }
                          placeholder={"Id Cobrança"}
                          autoComplete="off"
                        />
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <Select2Async
                          url={url.GET_ORDER_SELLER_AGENT_PAGINATION}
                          orderBy={"name"}
                          onChange={(event) => handlerSelect(event, "sellerId")}
                          id="sellerId"
                          placeholder={"CNPJ Fornecedor"}
                          value={statusSelects["sellerId"]}
                        />
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <Select2Async
                          url={url.GET_ORDER_BUYER_AGENT_PAGINATION}
                          orderBy={"name"}
                          onChange={(event) => handlerSelect(event, "buyerId")}
                          id="buyerId"
                          placeholder={"CNPJ Cliente Final"}
                          value={statusSelects["buyerId"]}
                          filters={{
                            sellerId: filter["sellerId"],
                          }}
                        />
                      </Col>
                      <Col lg={4} xl={3} md={6} xs={12} className="mb-3">
                        <InputDate
                          id="dueDate"
                          onChange={(event) =>
                            handleChangeFilterRangeCalendar(event, "dueDate")
                          }
                          placeholder="Data da cobrança"
                          values={[
                            filter?.["dueDate"]?.init,
                            filter?.["dueDate"]?.end,
                          ]}
                          options={{
                            mode: "range",
                            minDate: "2021-01-01",
                            maxDate: moment()
                              .add(1, "year")
                              .format("YYYY-MM-DD"),
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <hr />
                    </Row>
                    <Row className="mt-4">
                      <Col lg={4} className="col-sm-auto offset-sm-8">
                        <Row
                          className="row-sm-auto text-end"
                          style={{
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            className="btn btn-sm btn-light w-sm waves-effect waves-ligh col-auto mr-5"
                            onClick={(e) => clearFilters(e)}
                            title="Limpar Filtros"
                          >
                            Limpar Filtros
                          </button>
                          <button
                            type="submit"
                            title="Aplicar Filtros"
                            className="btn btn-sm btn-primary w-sm waves-effect waves-primary col-auto"
                          >
                            {props.t("Apply Search")}
                          </button>
                        </Row>
                      </Col>
                    </Row>

                    <Row
                      className="mt-4"
                      style={{
                        overflowX: "auto",
                      }}
                    >
                      <Col lg={12}>
                        <PaginationProvider
                          pagination={paginationFactory({
                            onSizePerPageChange: (sizePerPage, page) => {
                              filter["pagination.itemsPerPage"] = sizePerPage;
                              filter["pagination.currentPage"] = page;
                              setFilter(filter);
                              applySearch(page, sizePerPage, {
                                ...filter,
                              });
                            },
                            onPageChange: (page, sizePerPage) => {
                              filter["pagination.itemsPerPage"] = sizePerPage;
                              filter["pagination.currentPage"] = page;
                              setFilter(filter);
                              applySearch(page, sizePerPage, {
                                ...filter,
                              });
                            },
                            page: filter["pagination.currentPage"],
                            sizePerPage: filter["pagination.itemsPerPage"],
                            totalSize: totalOfItems,
                            showTotal: true,
                          })}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <div>
                              <BootstrapTable
                                onTableChange={(type, newState) => {
                                  return;
                                }}
                                keyField="id"
                                remote={true}
                                data={pager.results || []}
                                loading={true}
                                showTotal={true}
                                columns={columns}
                                rowEvents={rowEvents}
                                {...paginationTableProps}
                              />
                            </div>
                          )}
                        </PaginationProvider>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
        <BillingViewAdmin
          key={"BillingViewAdmin"}
          isOpen={isOpen}
          toggle={setIsOpen}
          afterClosed={applySearch}
          entityId={isOpenId}
        />
      </div>
    </React.Fragment>
  );
}

export default withRouter(withTranslation()(BillingV2));
