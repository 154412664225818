import React, {useState} from 'react';
import MetaTags from 'react-meta-tags';
import {Card, CardBody, Col, Container, Form, Modal, Nav, NavItem, NavLink, Row} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import InputText from "../../components/Common/InputText";
import LegalIdInput from "../../components/Common/LegalIdInput";
import TablePagination from "../../components/Common/TablePagination";
import OrderStatusDomain from "../../domains/OrderStatusDomain";
import $filter from "../../common/utils/$filter";
import PaginationService from "../../components/Common/PaginationService";
import * as url from "../../helpers/url_helper";
import OrderNew from "./OrderNew";
import SuccessDialog from "../../components/Common/SuccessDialog";
import ErrorDialog from "../../components/Common/ErrorDialog";
import classnames from "classnames";
import InputDate from "../../components/Common/InputDate";
import {downloadFileComplete, getSessionUserProfile, hasRole, patch} from "../../helpers/api_helper";
import PaymentMethodDomain from "../../domains/PaymentMethodDomain";
import Swal from "sweetalert2";
import OrderCreated from "./OrderCreated";
import {GET_ORDER_FORMALIZATION} from "../../helpers/url_helper";
import OrderView from "./OrderView";
import { USER_PROFILES } from '../../domains/UserProfileDomain';

function Order(props) {

    const [pager, setPager] = useState(PaginationService.defaultPager())
    const [filter, setFilter] = useState(PaginationService.defaultFilter())
    const [loading, setLoading] = useState(false)
    const [userProfile] = useState(getSessionUserProfile())
    const [error, setError] = useState(null)
    const [modalNewEntity, setModalNewEntity] = useState(false)
    const [modalOrderCreated, setModalOrderCreated] = useState({
        isOpen: false,
        entity: null
    })
    const [successMessage, setSuccessMessage] = useState(null)
    const [currentStatusTab, setCurrentStatusTab] = useState("ALL")

    const [orderSelected, setOrderSelected] = useState(null);
    const [orderViewModal, setOrderViewModal] = useState(false)

    const [statusTabs] = useState([
        {id: "ALL", title: props.t("All")},
        {id: "P", title: props.t("Pending")},
        {id: "V", title: props.t("In validation")},
        {id: "A", title: props.t("Approved")},
        {id: "C", title: props.t("Canceled")},
        {id: "R", title: props.t("Rejected")},
        {id: "E", title: props.t("Expired")},
    ])

    const openModalViewOrder = (entity) => {
        setOrderSelected(entity)
        setOrderViewModal(true)
    }

    const closeModalViewOrder = (entity) => {
        if (entity){
            const newPager = Object.assign({}, pager)

            let recordI = -1;

            for (let i in newPager.results) {
                if (newPager.results[i].id === entity.id){
                    newPager.results[i] = entity
                    recordI = i;
                }
            }
            if (entity.hadStatusUpdate && recordI > -1) {
                newPager.results.splice(recordI, 1);
            }
            setPager(newPager)
        }
        setOrderViewModal(false)
    }

    const searchByStatus = (status) => {
        if (currentStatusTab !== status) {
            setCurrentStatusTab(status)

            if (status !== 'ALL')
                setFilterField('status', status)
            else
                setFilterField('status', null)

            applySearch(0, 10)
        }
    }

    const applySearch = async (currentPage, itemsPerPage) => {
        try{
            const {pagerResult, filterResult} = await PaginationService.applySearch(
                url.GET_ORDER, 'orders', filter, currentPage, itemsPerPage, setLoading
            )
            setPager(pagerResult)
            setFilter(filterResult)
        }catch(e){
            setError(e)
        }
    }

    const handleChangeFilter = (event, field) => {
        filter[field] = event.target.value
        setFilterField('filter', filter)
    }

    const setFilterField = (name, value) => {
        filter[name] = value
        setFilter(filter);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setFilter(PaginationService.resetPagination(filter))
        applySearch()
    }

    function openModalNewEntity() {
        setModalNewEntity(true);
    }

    function openModalOrderCreated({entity}) {
        setModalOrderCreated({
            isOpen:true,
            entity: entity
        });
    }

    function setResultFromNewEntity(newEntity) {
        setFilter(PaginationService.defaultFilter())

        const newPager = PaginationService.defaultPager()
        newPager.results = [newEntity]
        setPager(
            newPager
        )
    }

    function closeModalNewEntity(resultModal) {
        setModalNewEntity(false)

        if (resultModal['event'] === 'SAVED' ){
            setResultFromNewEntity(resultModal['entity'])
            openModalOrderCreated({
                entity: resultModal['entity']
            })
        }
    }

    function closeModalOrderCreated(resultModal) {
        setModalOrderCreated({
            isOpen: false,
            entity: null
        })
    }

    const downloadTermo = async (entity, index) => {
        await downloadFileComplete(
            `${GET_ORDER_FORMALIZATION}/${entity.id}`, null, null, setLoading, `ORDER-${entity.id}-${entity.buyer.name}.pdf`, 'GET'
        )
    }

     const openRemoveConfirm = async (entity, index) => {

        const orderDate = $filter(entity.orderDate, 'DATE')
        const amount = $filter(entity.amount, 'MONEY')

        const html = `<div>
                <p><strong>${props.t("Cancellation of billing")}&nbsp;#${entity.id}</strong>
                    <br/><br/>
                    <strong>${entity.buyer.name}</strong>
                     <br/>
                     <small>${props.t("Billing Date")}: ${orderDate}</small><br/>
                     <small>${props.t("Total amount")}: ${amount}</small>
                </p>
            </div>`

        Swal.fire({
            title: props.t("Are you sure you want to cancel the billing?"),
            icon: 'question',
            html: html,
            showCloseButton: false,
            showCancelButton: true,
            confirmButtonText: props.t("Confirm"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                cancelOrder(entity, index)
            }
        })
    }

    const cancelOrder = async (entity, index) => {
        try{
            const finalUrl = `${url.PATCH_ORDER}/${entity.id}`
            const response = await patch(
                finalUrl, {
                    status: 'C'
                }, null, setLoading
            )

            const newPager = Object.assign({}, pager)
            newPager.results[index] = response
            setPager(newPager)

            setSuccessMessage({
                detail: props.t("Billing canceled successfully")
            })

        }catch(e){
            setError(e)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Billing")}</title>
                </MetaTags>

                <Container fluid>
                    <Breadcrumbs title={props.t("Billing")} breadcrumbItem={props.t("Manage Billing")}/>

                    <Form id="searchForm" onSubmit={(e) => {
                        handleSubmit(e)
                    }}>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <p>{props.t("Filters parameters")}</p>
                                        <Row>
                                            <Col lg={4} className="mb-3">
                                                <LegalIdInput
                                                    id="buyerId"
                                                    onChange={event => handleChangeFilter(event, "buyerId")}
                                                    placeholder={props.t("Buyer LegalId")}/>
                                            </Col>
                                            <Col lg={4} className="mb-3">
                                                <InputText id="buyerName"
                                                           onChange={event => handleChangeFilter(event, "buyerName")}
                                                           placeholder={props.t("Buyer Name")}/>
                                            </Col>
                                            <Col lg={4} className="mb-3">
                                                <InputDate id="orderDate"
                                                           onChange={event => handleChangeFilter(event, "orderDate")}
                                                           placeholder={props.t("Billing Date")}/>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col lg={4} className="mb-3">
                                                <InputText
                                                    id="orderId"
                                                    onChange={event => handleChangeFilter(event, "orderId")}
                                                    placeholder={props.t("Billing Number")}/>
                                            </Col>
                                            <Col lg={4} className="mb-3">
                                                <InputText id="invoiceNumber"
                                                           onChange={event => handleChangeFilter(event, "invoiceNumber")}
                                                           placeholder={props.t("Identifier")}/>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={12}>
                                                <div className="text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-sm btn-light w-sm waves-effect waves-light"
                                                    >
                                                        {props.t("Apply Search")}
                                                    </button>
                                                    {" "}

                                                    {
                                                        userProfile === USER_PROFILES.SELLER &&  hasRole("order.write") ?
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-primary w-sm waves-effect waves-light"
                                                                onClick={()=> {openModalNewEntity()}}
                                                            >
                                                                {props.t("New")}
                                                            </button>
                                                            : ""
                                                    }
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col lg={12}>
                                                <Nav
                                                    tabs
                                                    className="nav-tabs-custom nav-justified"
                                                >
                                                    {statusTabs.map((tab, index) => {
                                                        return (
                                                            <NavItem key={tab.id}>
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: currentStatusTab === tab.id,
                                                                    })}
                                                                    onClick={() => {
                                                                        searchByStatus(tab.id)
                                                                    }}
                                                                >
                                                                    {tab.title}
                                                                </NavLink>
                                                            </NavItem>
                                                        )
                                                    })}
                                                </Nav>
                                            </Col>
                                        </Row>

                                        <Row className="mt-4">
                                            <Col lg={12}>
                                                <TablePagination
                                                    pager={pager}
                                                    applySearch={applySearch}
                                                    loading={loading}>
                                                    <thead className="table-light">
                                                    <tr>
                                                        <th className="col-md-2 text-center">{props.t("Status")}</th>

                                                        {
                                                            userProfile === USER_PROFILES.ADM?
                                                                <th className="col-md-3">{props.t("Seller")}</th>
                                                                : ""
                                                        }

                                                        <th className="col-md-4">{props.t("Customer and Billing")}</th>
                                                        <th className="col-md-1">{props.t("Billing Date")}</th>
                                                        <th className="col-md-2">{props.t("Receipt plan")}</th>
                                                        <th className="col-md-2">{props.t("Payment period")}</th>
                                                        <th className="col-md-1">{props.t("Total amount")}</th>
                                                        <th className="col-md-1"></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {(pager.results || []).map((entity, index) => (
                                                        <tr key={entity['legalId']} className="align-middle"
                                                        >
                                                            <td scope="row" className="text-center"
                                                                style={{cursor: "pointer"}} onClick={() => {
                                                                openModalViewOrder(entity);
                                                            }}>
                                                                <span
                                                                    className={OrderStatusDomain.getClassFromValue(entity.status)}>
                                                                    {OrderStatusDomain.getNameFromValue(entity.status)}
                                                                </span>
                                                            </td>

                                                            {
                                                                userProfile === USER_PROFILES.ADM ?
                                                                    <td  style={{cursor: "pointer"}} onClick={() => {
                                                                        openModalViewOrder(entity);
                                                                    }}>
                                                                        {entity.seller.name}
                                                                    </td>
                                                                    : ""
                                                            }

                                                            <td  style={{cursor: "pointer"}} onClick={() => {
                                                                openModalViewOrder(entity);
                                                            }}>
                                                                {entity.buyer.name} <br/>
                                                                <span className="card-title-desc font-size-12">
                                                                    {props.t("Billing")} #{entity.id ? entity.id : " "}
                                                                    {
                                                                        entity.invoiceNumber ? ` / ${entity.invoiceNumber}` : ""
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td  style={{cursor: "pointer"}} onClick={() => {
                                                                openModalViewOrder(entity);
                                                            }}>
                                                                {$filter(entity.orderDate, 'DATE')}
                                                            </td>
                                                            <td  style={{cursor: "pointer"}} onClick={() => {
                                                                openModalViewOrder(entity);
                                                            }}>
                                                                {
                                                                    PaymentMethodDomain.getInstanceFromValue(
                                                                        entity.paymentMethod
                                                                    )['name']}
                                                            </td>
                                                            <td  style={{cursor: "pointer"}} onClick={() => {
                                                                openModalViewOrder(entity);
                                                            }}>
                                                                {entity.deadline}
                                                            </td>
                                                            <td  style={{cursor: "pointer"}} onClick={() => {
                                                                openModalViewOrder(entity);
                                                            }}>
                                                                {$filter(entity.amount, 'MONEY')}
                                                            </td>

                                                            {
                                                                entity.status === 'P' &&
                                                                userProfile !== USER_PROFILES.ADM ? (
                                                                    <td className="col-md-1 text-center"
                                                                        style={{cursor: "pointer"}}
                                                                        onClick={() => {
                                                                        openRemoveConfirm(entity, index)
                                                                    }}>
                                                                        <i className="fa fa-trash"></i>
                                                                    </td>
                                                                ) :  <td className="col-md-1 text-center"></td>
                                                            }
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </TablePagination>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>

                    {
                        modalNewEntity ?
                            (
                                <OrderNew
                                    isOpen={modalNewEntity}
                                    callback={closeModalNewEntity}
                                />
                            ) : ("")
                    }
                    {
                        modalOrderCreated.isOpen ?
                            (
                                <OrderCreated
                                    isOpen={modalOrderCreated.isOpen}
                                    entity={modalOrderCreated.entity}
                                    callback={closeModalOrderCreated}
                                />
                            ) : ("")
                    }

                    {
                        orderViewModal ?
                            (
                                <OrderView
                                    entity={orderSelected}
                                    isOpen={orderViewModal}
                                    callback={closeModalViewOrder}
                                />
                            ) : ("")
                    }

                </Container>
            </div>

            {
                error ? (
                    <ErrorDialog
                        title={error['title']}
                        onClose={() => {
                            setError(null)
                        }}
                    >
                        <p>{error['detail']} </p>
                    </ErrorDialog>
                ) : ("")
            }

            {
                successMessage ? (
                    <SuccessDialog
                        title={successMessage['title']}
                        onClose={() => {
                            setSuccessMessage(null)
                        }}
                    >
                        <p>{successMessage['detail']} </p>
                    </SuccessDialog>
                ) : ("")
            }

        </React.Fragment>
    );
}

export default withRouter(withTranslation()(Order))