import { post, put } from "../../helpers/api_helper";

import { AuthService } from "./authService";
import { UserService } from "./userService";

const http = {
  post,
  put,
};

export const userService = new UserService(http);
export const authService = new AuthService(http);
