import React, { useEffect, useState, useMemo } from "react";
import MetaTags from "react-meta-tags";
import widget from "../../assets/images/widget-img.png";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Card, Col, Container, Row, CardBody, Label } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import PaginationService from "../../components/Common/PaginationService";
import * as url from "../../helpers/url_helper";
import Loader from "../../components/Common/Loader";
import EndUserReceivableBox from "../../components/Common/EndUserReceivableBox";
import EndUserBySellerReceivableBox from "../../components/Common/EndUserBySellerReceivableBox";
import ManageOptinNew from "../Opt-in/ManageOptinNew";
import SuccessDialog from "../../components/Common/SuccessDialog";
import {getSessionUserProfile} from "../../helpers/api_helper.js";
import index from "react-select";
import { USER_PROFILES } from "../../domains/UserProfileDomain.js";
const { uuid } = require("uuidv4");

function SearchReceivablesSchedule(props) {
  const history = useHistory();
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(PaginationService.defaultFilter());
  const [loading, setLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState({
    breadcrumbItem: props.t("Search receivables schedule"),
    title: props.t("Receivables"),
  });
  const [modalNewOptin, setModalNewOptin] = useState(false);
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [userProfile] = useState(getSessionUserProfile());
  const [successMessage, setSuccessMessage] = useState(null);
  const [messages, setMessages] = useState({});
  const [lastValidResult, setLastValidResult] = useState(null);

  // State to store the custom no results message
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const resultReceivables = useMemo(() => lastValidResult, [lastValidResult]);

  const renderItem = (rec, itemKey) =>
      userProfile.toString() === USER_PROFILES.SELLER ? (
          <EndUserBySellerReceivableBox
              navigateToEdit={navigateToEdit}
              rec={rec}
              itemKey={itemKey}
          />
      ) : (
          <EndUserReceivableBox
              navigateToEdit={navigateToEdit}
              rec={rec}
              itemKey={itemKey}
          />
      );

  useEffect(() => {
    if (userProfile === USER_PROFILES.SELLER) {
      setPageTitle({
        breadcrumbItem: props.t("Search customer"),
        title: props.t("Customer"),
      });
      setMessages({
        ADD_END_USER: props.t("Add customer for review"),
        NO_END_USER_REGISTERED: props.t("You don't have any customers yet"),
        ADD_END_USER_EXTENDED: props.t(
            "Add customers to your wallet and sell more with Cash2Pay"
        ),
      });
    } else {
      setMessages({
        ADD_END_USER: props.t("New Opt-in"),
        NO_END_USER_REGISTERED: props.t(
            "You do not yet have an active Opt-in to access receivables schedules"
        ),
        ADD_END_USER_EXTENDED: props.t(
            "Request a new Opt-in and start operating in the receivables market"
        ),
      });
    }

    fetchData(1, true);
  }, []);

  useEffect(() => {
    if (results.length > 0) {
      setLastValidResult(results.length);
    }
  }, [results, filter]);

  const navigateToEdit = (receivable) => {
    const storageId = uuid();
    localStorage.setItem(
        storageId,
        JSON.stringify({
          oid: storageId,
          receivingEndUser: receivable["receivingEndUser"],
        })
    );
    history.push(`/receivables/${storageId}`);
  };

  const fetchData = async (page, firstAccess) => {
    setLoading(true);
    try {
      const pagination = {
        currentPage: page - 1,
        itemsPerPage: filter["pagination.itemsPerPage"] || 10,
      };
      const response = await PaginationService.applySearch(
          url.GET_RECEIVABLES,
          "receivables",
          filter,
          pagination.currentPage,
          pagination.itemsPerPage
      );

      if (response.pagerResult.results.length === 0 && response.currentPage === 0 && response.filterResult["q"]) {
        setNoRecordsFound(true);
        setResults([]);

          if(userProfile === USER_PROFILES.SELLER) {
            setPageTitle({
                  breadcrumbItem: props.t("Add customer for review"),
                  title: props.t("Customer"),
            });
            setMessages({
              ADD_END_USER: props.t("Add customer for review"),
              NO_END_USER_REGISTERED: props.t("Your search returned no results."),
              ADD_END_USER_EXTENDED: props.t(
                  "Check the search data; if it is correct, the customer does not exist in your portfolio."
              ),
            });
          } else {
            setMessages({
            ADD_END_USER: props.t("New Opt-in"),
            NO_END_USER_REGISTERED: props.t("Your search returned no results."),
            ADD_END_USER_EXTENDED: props.t(
                "Check the search data; if it is correct, the customer does not exist in your portfolio."
            ),
          });}
      } else {
        setResults((prevResults) =>
            firstAccess
                ? response.pagerResult.results
                : [...prevResults, ...response.pagerResult.results]
        );
        setNoRecordsFound(false);
      }

      setFilter(response.filterResult);

      if (!firstAccess) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    } catch (e) {
      console.error("Error Busca", e);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = async (e) => {
    const scrollTop = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;
    const clientHeight = e.target.clientHeight;

    const tolerance = 5;

    if (
        Math.abs(scrollHeight - scrollTop - clientHeight) <= tolerance &&
        !loading
    ) {
      await fetchData(currentPage + 1, false);
    }
  };

  const addCustomerForReview = () => {
    setModalNewOptin(true);
  };

  const handleChangeFilter = (event) => {
    const { name, type, value, checked } = event.target;

    setFilter((prevFilter) => {
      if (type === 'checkbox') {
        if (checked) {
          return {
            ...prevFilter,
            [name]: 'on',
          };
        } else {
          const { [name]: _, ...rest } = prevFilter;
          return rest;
        }
      } else {
        return {
          ...prevFilter,
          [name]: value ?? "",
        };
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchData(1, true);
  };

  const closeModalNewOptin = (resultModal) => {
    setModalNewOptin(false);
    if (resultModal && resultModal.entity) {
      setSuccessMessage({
        detail: props.t("Opt-in successfully registered"),
      });

      setTimeout(() => {
        fetchData(1, true);
      }, 1000);
    }
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{pageTitle.title}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
                title={pageTitle.title}
                breadcrumbItem={pageTitle.breadcrumbItem}
            />

            <Row>
              <Col lg={12}>
                <div
                    className="d-xl-flex"
                    style={{ justifyContent: "space-between" }}
                >
                  <div
                      className="w-100"
                      style={{ height: "100vh", overflowY: "auto" }}
                      onScroll={loadMore}
                  >
                    <div className="d-flex flex-wrap">
                      {noRecordsFound ? (
                          <Row>
                            <Col lg={12}>
                              <Card className="bg-pattern">
                                <CardBody>
                                  <Row className="align-items-center">
                                    <Col sm={5}>
                                      <div className="avatar-xs mb-3">
                                    <span className="avatar-title rounded-circle bg-light font-size-24">
                                      <i className="mdi mdi-bullhorn-outline text-primary"></i>
                                    </span>
                                      </div>
                                      <p
                                          onClick={() => addCustomerForReview()}
                                          className="font-size-18"
                                      >
                                        {messages.NO_END_USER_REGISTERED}
                                        <b>&nbsp;{messages.ADD_END_USER_EXTENDED}</b>{" "}
                                        <i className="mdi mdi-arrow-right"></i>
                                      </p>
                                      <p>{noResultsMessage}</p>
                                          <div className="mt-4">
                                        <Link
                                            to="#!"
                                            onClick={() => addCustomerForReview()}
                                            className="btn btn-success waves-effect waves-light"
                                        >
                                          {messages.ADD_END_USER}!
                                        </Link>
                                      </div>
                                    </Col>
                                    <Col sm={4}>
                                      <div className="mt-4 mt-sm-0">
                                        <img src={widget} className="img-fluid" alt="" />
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                      ) : (
                          results.map((item, index) => (
                              <div
                                  key={index}
                                  className="card-box"
                                  style={{ margin: "10px", width: "calc(33% - 20px)", minWidth: "250px", maxWidth: "500px" }}
                              >
                                {renderItem(item, index)}
                              </div>
                          ))
                      )}
                    </div>
                  </div>

                  <div
                      className="d-none d-lg-block"
                      style={{ flex: "0 0 300px" }}
                  >
                    <div className="ui-elements-demo-bar card">
                      <h4 className="card-title">{props.t("Filters")}</h4>

                      <Row className="mb-4">
                        <Col>
                          <form
                              className="app-search d-none d-lg-block"
                              onSubmit={handleSubmit}
                          >
                            <div className="position-relative">
                              <input
                                  type="text"
                                  name="q"
                                  className="form-control"
                                  placeholder="Pesquisar..."
                                  onChange={handleChangeFilter}
                              />
                              <span className="mdi mdi-magnify" />
                            </div>
                          </form>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>
                          <div
                              className="form-check form-switch form-switch-lg mb-lg-3"
                              dir="ltr"
                          >
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="onlyTodayCheck"
                                name="onlyTodayCheck"
                                onChange={handleChangeFilter}
                            />
                            <Label
                                className="form-check-label"
                                for="onlyTodayCheck"
                            >
                              Apenas de hoje
                            </Label>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mb-5">
                        <Col>
                          <div className="form-check form-switch form-switch-lg mb-lg-3">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="onlyBalanceCheck"
                                name="onlyBalanceCheck"
                                onChange={handleChangeFilter}
                            />
                            <Label
                                className="form-check-label"
                                for="onlyBalanceCheck"
                            >
                              Com saldo disponível
                            </Label>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <div className="button-items text-center">
                            <button
                                type="button"
                                onClick={() => fetchData(1, true)}
                                className="btn btn-light w-sm waves-effect waves-light w-100"
                            >
                              {props.t("Apply Search")}
                            </button>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <div className="button-items text-center mt-3">
                            <button
                                type="button"
                                onClick={addCustomerForReview}
                                className="btn btn-primary waves-effect waves-light h-50 w-100"
                            >
                              {messages.ADD_END_USER}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {loading && <Loader loading={loading} />}

        {modalNewOptin && (
            <ManageOptinNew isOpen={modalNewOptin} callback={closeModalNewOptin} />
        )}

        {successMessage && (
            <SuccessDialog
                title={successMessage.title}
                onClose={() => setSuccessMessage(null)}
            >
              <p>{successMessage.detail}</p>
            </SuccessDialog>
        )}
      </React.Fragment>
  );
}

export default withRouter(withTranslation()(SearchReceivablesSchedule));