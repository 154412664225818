import * as url from "../../helpers/url_helper";

export class AuthService {
  constructor(http) {
    this.http = http;
  }

  async recoveryPassword(email) {
    return this.http.post(url.POST_REQUEST_NEW_PASSWORD, { email }, null);
  }
}
